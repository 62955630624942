<template>
  <div class="container mt-5">
    <h1 class="mb-4">Release hinzufügen</h1>
    <form @submit.prevent="submitRelease" class="needs-validation" novalidate>
      <div class="form-group row">
        <label for="title" class="col-sm-3 col-form-label">Titel:</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="title" v-model="release.title" required>
          <div class="invalid-feedback">
            Bitte geben Sie einen Titel ein.
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="category" class="col-sm-3 col-form-label">Kategorie:</label>
        <div class="col-sm-9">
          <select class="form-control" id="category" v-model="release.category" required>
            <option value="">Bitte wählen...</option>
            <option value="Filme">Filme</option>
            <option value="Serien">Serien</option>
            <option value="Videospiele">Videospiele</option>
            <option value="Musik">Musik</option>
          </select>
          <div class="invalid-feedback">
            Bitte wählen Sie eine Kategorie aus.
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="releaseDate" class="col-sm-3 col-form-label">Release Date:</label>
        <div class="col-sm-9">
          <input type="date" class="form-control" id="releaseDate" v-model="release.releaseDate" required>
          <div class="invalid-feedback">
            Bitte geben Sie ein gültiges Datum ein.
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="platform" class="col-sm-3 col-form-label">Platform:</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="platform" v-model="release.platform" required>
          <div class="invalid-feedback">
            Bitte geben Sie eine Plattform ein.
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="url" class="col-sm-3 col-form-label">URL:</label>
        <div class="col-sm-9 d-flex">
          <input type="url" class="form-control" id="url" v-model="release.url" required>
          <button type="button" class="btn btn-secondary ml-2" @click="searchTitle">
            Suchen
          </button>
          <div class="invalid-feedback">
            Bitte geben Sie eine gültige URL ein.
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="imageUrl" class="col-sm-3 col-form-label">Image URL:</label>
        <div class="col-sm-9">
          <input type="url" class="form-control" id="imageUrl" v-model="release.imageUrl" required>
          <div class="invalid-feedback">
            Bitte geben Sie eine gültige Bild-URL ein.
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12 text-center">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { config } from "@/config";

export default {
  data() {
    return {
      release: {
        title: '',
        category: '',
        releaseDate: '',
        platform: '',
        url: '',
        imageUrl: ''
      }
    };
  },
  methods: {
    searchTitle() {
      if (!this.release.title) {
        alert('Bitte geben Sie zuerst einen Titel ein.');
        return;
      }

      let searchUrl = '';
      const encodedTitle = encodeURIComponent(this.release.title);

      switch (this.release.category) {
        case 'Filme':
        case 'Serien':
          searchUrl = `https://www.themoviedb.org/search?query=${encodedTitle}`;
          break;
        case 'Videospiele':
          searchUrl = `https://www.igdb.com/search?utf8=%E2%9C%93&q=${encodedTitle}`;
          break;
        case 'Musik':
          searchUrl = `https://www.last.fm/search?q=${encodedTitle.replace(/%20/g, '+')}`;
          break;
        default:
          alert('Bitte wählen Sie zuerst eine Kategorie aus.');
          return;
      }

      window.open(searchUrl, '_blank');
    },
    async submitRelease() {
      try {
        console.log(JSON.stringify(this.release))
        const response = await fetch(`${config.apiUrl}/releases/add`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.release)
        });

        if (response.ok) {
          alert('Release hinzugefügt!');
          // Zurücksetzen des Formulars
          this.release = {
            title: '',
            category: '',
            releaseDate: '',
            platform: '',
            url: '',
            imageUrl: ''
          };
        } else {
          alert('Fehler beim Hinzufügen des Releases!');
        }
      } catch (error) {
        console.error('Fehler:', error);
        alert('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
      }
    }
  }
};
</script>

<style scoped>
.invalid-feedback {
  display: none;
}

.d-flex {
  display: flex !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}
</style>
