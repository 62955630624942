<script setup>
import { ref, onMounted, watch, onUnmounted, computed } from "vue";
import axios from "axios";
import { fbauth } from "@/firebase";
import { config } from "@/config";

const log = ref([]);
const selectedLevel = ref('ERROR');
const selectedProgramName = ref('');

// Filter for formatting date
const formatDate = (dateString) => {
  const options = { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit" };
  const date = new Date(dateString);
  return date.toLocaleDateString("de-DE", options);
};

// Function to fetch data
const fetchData = async () => {
  const auth = fbauth;

  if (auth.currentUser) {
    try {
      const authToken = await auth.currentUser.getIdToken();

      const response = await axios.get(`${config.apiUrl}/log`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });

      log.value = response.data.map((logEntry) => ({
        ...logEntry,
        timestamp_neu: formatDate(logEntry.timestamp)
      }));


      // Ensure that all entries have valid timestamps before sorting
      log.value = log.value.filter(entry => entry.timestamp);

      // Sort by timestamp in descending order
      log.value.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));


    } catch (error) {
      console.error("Error fetching data:", error);
    }
  } else {
    console.warn("User is not authenticated");
  }
};

// Observer for authentication state changes
const unsubscribe = fbauth.onAuthStateChanged((user) => {
  console.log("Auth state changed:", user);
  // Fetch data when the authentication state changes
  fetchData();
});

// Lifecycle hook to unsubscribe from the observer when the component is unmounted
onUnmounted(() => {
  console.log("Component is unmounted. Unsubscribing from auth observer.");
  unsubscribe();
});

// Watch for changes in releases to trigger the maxTimestamp computation
watch(log, () => {
  console.log("releases changed. Recomputing maxTimestamp.");
});

// Compute unique program names from log entries
const uniqueProgramNames = computed(() => {
  const programs = new Set();
  (log.value || []).forEach(entry => {
    if (entry.program_name) {
      programs.add(entry.program_name);
    }
  });
  return Array.from(programs);
});

// Filter log entries based on selected level and selected program name
const filteredLog = computed(() => {
  return (log.value || []).filter(entry => {
    const matchesLevel = !selectedLevel.value || entry.level === selectedLevel.value;
    const matchesProgramName = !selectedProgramName.value || entry.program_name === selectedProgramName.value;
    return matchesLevel && matchesProgramName;
  });
});
</script>

<template>
  <div class="container mt-4">
    <h2 class="mb-4">Log Entries</h2>
    <div class="form-row mb-3">
      <div class="col-md-6">
        <label for="levelFilter">Filter by Level:</label>
        <select id="levelFilter" class="form-control" v-model="selectedLevel">
          <option value="">Alle</option>
          <option value="INFO">INFO</option>
          <option value="WARNING">WARNING</option>
          <option value="ERROR">ERROR</option>
        </select>
      </div>
      <div class="col-md-6">
        <label for="programNameFilter">Filter by Program Name:</label>
        <select id="programNameFilter" class="form-control" v-model="selectedProgramName">
          <option value="">All</option>
          <option v-for="program in uniqueProgramNames" :key="program" :value="program">
            {{ program }}
          </option>
        </select>
      </div>
    </div>
    <table class="table table-bordered table-striped">
      <thead class="thead-dark">
      <tr>
        <th scope="col">Timestamp</th>
        <th scope="col">Level</th>
        <th scope="col">Program Name</th>
        <th scope="col">Message</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(logEntry, index) in filteredLog" :key="index">
        <td>{{ logEntry.timestamp_neu }}</td>
        <td>{{ logEntry.level }}</td>
        <td>{{ logEntry.program_name }}</td>
        <td>{{ logEntry.message }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="scss">
</style>
