import { initializeApp } from "firebase/app";
import { browserLocalPersistence, getAuth, setPersistence } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyADjiVNaCrt4Wf8CskMrqFMEe86_hdNiNc",
  authDomain: "fabiankratzer-8abf7.firebaseapp.com",
  projectId: "fabiankratzer-8abf7",
  storageBucket: "fabiankratzer-8abf7.appspot.com",
  messagingSenderId: "1019890603827",
  appId: "1:1019890603827:web:dc3304e8f5bb9160769046",
  measurementId: "G-VRFYMNW6Y9"
};
console.log("Initializing Firebase app...");
const firebaseApp = initializeApp(firebaseConfig);
console.log("Firebase app initialized.");

const fbauth = getAuth(firebaseApp);

setPersistence(fbauth, browserLocalPersistence);



export { firebaseApp, fbauth };