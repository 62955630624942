<template>
  <div>
    <div id="reader" style="width: 100%;"></div>
    <button @click="startScanner">Start Scanner</button>
    <button @click="stopScanner">Stop Scanner</button>
    <p v-if="scannedResult">Scanned Code: {{ scannedResult }}</p>
    <div v-if="product">
      <h2>Product Information:</h2>
      <p><strong>Title:</strong> {{ product.product_name }}</p>
      <p><strong>Brand:</strong> {{ product.brand }}</p>
      <p><strong>Category:</strong> {{ product.category }}</p>
      <p><strong>Manufacturer:</strong> {{ product.manufacturer }}</p>
    </div>
  </div>
</template>

<script>
import { Html5Qrcode } from "html5-qrcode";

export default {
  data() {
    return {
      html5QrCode: null,
      scannedResult: null,
      product: null,
    };
  },
  methods: {
    startScanner() {
      const config = {
        fps: 10, // Frames per second
        qrbox: { width: 250, height: 250 }, // Scanning box
      };

      this.html5QrCode = new Html5Qrcode("reader");

      this.html5QrCode.start(
        { facingMode: "environment" }, // "environment" for the back camera
        config,
        (decodedText) => {
          this.scannedResult = decodedText;
          this.stopScanner(); // Automatically stop scanner after a successful scan
          this.searchProduct(decodedText); // Search for the product
        },
        (errorMessage) => {
          console.log(`Scan error: ${errorMessage}`);
        }
      ).catch((err) => {
        console.error("Unable to start scanning", err);
      });
    },
    stopScanner() {
      if (this.html5QrCode) {
        this.html5QrCode.stop().then(() => {
          this.html5QrCode.clear();
        }).catch((err) => {
          console.error("Unable to stop scanning", err);
        });
      }
    },
    async searchProduct(barcode) {
      const apiKey = "i0loc1eoq7ll2pnvaoaqdu9gaio5eo"; // Replace with your API key
      const url = `https://api.barcodelookup.com/v3/products?barcode=${barcode}&formatted=y&key=${apiKey}`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data.products && data.products.length > 0) {
          const product = data.products[0];
          this.product = {
            product_name: product.product_name,
            brand: product.brand,
            category: product.category,
            manufacturer: product.manufacturer,
          };
        } else {
          this.product = null;
          alert("No product found.");
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
        alert("Error fetching product data.");
      }
    }
  },
  beforeDestroy() {
    this.stopScanner();
  }
};
</script>

<style scoped>
#reader {
  width: 100%;
  max-width: 500px;
  margin: auto;
}
</style>
