<script setup>
import { ref, onMounted, watch, onUnmounted, computed } from "vue";
import axios from "axios";
import { fbauth } from "@/firebase";
import { config } from "@/config";

const comics = ref([]);

// Function to fetch data
const fetchData = async () => {
  try {
    const auth = fbauth;

    if (auth.currentUser) {
      const authToken = await auth.currentUser.getIdToken();

      const response = await axios.get(`${config.apiUrl}/comics-wunschliste`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });

      comics.value = response.data.sort((a, b) => new Date(a.ReleaseDate) - new Date(b.ReleaseDate));

    } else {
      console.warn("User is not authenticated");
      // Handle the case where the user is not authenticated
    }

  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Observer for authentication state changes
const unsubscribe = fbauth.onAuthStateChanged((user) => {
  console.log("Auth state changed:", user);
  // Fetch data when the authentication state changes
  fetchData();
});

// Lifecycle hook to unsubscribe from the observer when the component is unmounted
onUnmounted(() => {
  console.log("Component is unmounted. Unsubscribing from auth observer.");
  unsubscribe();
});


// Computed property to calculate the total cover price for unpurchased comics
const getTotalCoverPrice = computed(() => {
  return comics.value.reduce((total, comic) => {

      return total + (comic.CoverPrice || 0); // Adding cover price, handling possible null values

  }, 0);
});


</script>


<template>
  <!--  <div class="table-responsive">
      <table class="table table-hover table-sm">
        <thead>
        <tr>
          <th scope="col">Title</th>
          <th scope="col">Release Date</th>
          <th scope="col">Marked Read</th>
          <th scope="col">Image</th>
        </tr>
        </thead>
        <tbody class="table-group-divider">
        <tr v-for="comic in filteredComics" :key="comic.id">
          <td><a :href="comic.URL_LoCBG" target="_blank">{{comic.FullTitle }}</a></td>
          <td>{{ comic.ReleaseDate }}</td>
          <td>{{ comic.MarkedRead }}</td>
          <td>
            &lt;!&ndash; Assuming ImageUrl is a path to the image &ndash;&gt;
            <img :src="comic.ImageUrl" alt="Comic Image" style="max-width: 100px; max-height: 100px;" />
          </td>
        </tr>
        </tbody>
      </table>
    </div>-->


  <div>
    <div class="container">
      <h4>Total Items: {{ comics.length }}</h4>
      <h4 v-if="comics.length > 0">Total Cover Price for Unpurchased Comics: {{ getTotalCoverPrice }}€</h4>
    </div>


    <div class="row row-cols-2 row-cols-md-3 g-4">
      <div v-for="comic in comics" :key="comic.id" class="col">
        <div class="card h-100">
          <!-- Yellowish transparent layer when ReleaseDate in future -->
          <div v-if="(new Date(comic.ReleaseDate)).getMonth() === (new Date()).getMonth() && (new Date(comic.ReleaseDate)).getFullYear() === (new Date()).getFullYear()" class="greenish-layer"></div>
          <div v-else-if="new Date(comic.ReleaseDate) > new Date()" class="yellowish-layer"></div>

          <div class="row g-0 m-auto">
            <div class="col-md-4">
              <img class="card-img-top" :src="comic.ImageUrl" :alt="'Cover ' + comic.FullTitle">
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <h5 class="card-title">{{ comic.FullTitle }}</h5>
                <p class="card-text">{{ comic.ReleaseDate ? new Date(comic.ReleaseDate).toLocaleDateString() : "" }}</p>
                <p class="card-text">Preis: {{ comic.CoverPrice }}€</p>
                <p class="card-text">{{ comic.Grund }}</p>
                <p><a :href="comic.URL_Paninishop">Panini</a></p>
                <p><a :href="comic.URL_LoCBG">LOCG</a></p>
              </div>
            </div>
          </div>
          <div class="card-footer">

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>
.yellowish-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 0, 0.5); /* Yellowish with 50% opacity */
  pointer-events: none;
  z-index: 1; /* Ensure the yellowish layer is above the card */
}

.greenish-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 255, 0, 0.5); /* Greenish with 50% opacity */
  pointer-events: none;
  z-index: 1; /* Ensure the greenish layer is above the card */
}
</style>