<template>
  <div class="container mt-4">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="d-flex">
        <select v-model="selectedMonth" @change="fetchData" class="form-select w-auto me-2">
          <option v-for="month in months" :key="month.value" :value="month.value">
            {{ month.text }}
          </option>
        </select>
        <select v-model="selectedYear" @change="fetchData" class="form-select w-auto">
          <option v-for="year in years" :key="year" :value="year">
            {{ year }}
          </option>
        </select>
      </div>
      <h4>Gesamt: {{ formatAmount(totalExpenses()) }}</h4>
    </div>
    <input type="text" class="form-control mb-3" placeholder="Search expenses..." v-model="searchQuery">

    <div class="row">
      <div class="col-md-12 mb-3" v-for="expense in filteredExpenses()" :key="expense.id">
        <div class="card">
          <div v-if="expense.Vorbestellung !== null && new Date(expense.Vorbestellung) < new Date() && expense.Erhalten !== 'Ja'" class="redish-layer"></div>
          <div v-else-if="expense.Erhalten !== 'Ja'" class="yellowish-layer"></div>
          <div class="card-header">
            <p class="card-text">{{ new Date(expense.Datum).toLocaleDateString("de-DE", {
              weekday: "long",
              day: "2-digit",
              month: "2-digit",
              year: "numeric"
            }) }}</p>
          </div>
          <div class="card-body row">
            <div class="col-6">
              <h5 class="card-title">{{ expense.Name }}</h5>
              <p class="card-text">{{ expense.Shop }}</p>
              <span v-if="expense.Vorbestellung !== null"
                    class="me-1 badge text-bg-warning">Lieferung bis: {{ new Date(expense.Vorbestellung).toLocaleDateString("de-DE")
                }}</span>
            </div>
            <div :class="'col-6 align-content-center prio-' + expense.PRIO">
              <span class="">{{ formatAmount(expense.Betrag) }}</span>
            </div>
          </div>
          <div class="card-footer">
            <span class="me-1 badge text-bg-info">
               {{ expense.Oberkategorie }}
             </span>
            <span class="me-1 badge text-bg-info">
               {{ expense.Kategorie }}
             </span>
            <span :class="'me-1 badge text-' + (expense.Erhalten === 'Ja' ? 'bg-success' : 'bg-danger')">
               {{ expense.Erhalten === "Ja" ? "Erhalten" : "Nicht erhalten" }}
             </span>
            <span v-if="expense.Gemeinschaftskonto === 'Ja'" class="me-1 badge text-bg-warning">
               Gemeinschaftskonto
             </span>
            <span v-if="expense.Steuererklärung === 'Ja'" class="me-1 badge text-bg-success">
               Steuererklärung: Ja
             </span>
            <span v-if="expense.Steuererklärung === 'Vielleicht'" class="me-1 badge text-bg-secondary">
               Steuererklärung: Vielleicht
             </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, onUnmounted, watch } from "vue";
import axios from "axios";
import { fbauth } from "@/firebase";
import { config } from "@/config";

export default {
  data() {
    return {
      expenses: [],
      searchQuery: "",
      selectedMonth: new Date().getMonth() + 1,
      selectedYear: new Date().getFullYear(),
      months: [
        { value: 1, text: "Januar" },
        { value: 2, text: "Februar" },
        { value: 3, text: "März" },
        { value: 4, text: "April" },
        { value: 5, text: "Mai" },
        { value: 6, text: "Juni" },
        { value: 7, text: "Juli" },
        { value: 8, text: "August" },
        { value: 9, text: "September" },
        { value: 10, text: "Oktober" },
        { value: 11, text: "November" },
        { value: 12, text: "Dezember" }
      ],
      years: Array.from({ length: 50 }, (_, i) => new Date().getFullYear() - i)
    };
  },
  methods: {
    // Function to format amount with thousands separators and Euro symbol
    formatAmount(amount) {
      return amount.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "€";
    },
    // Function to filter expenses based on search query
    filteredExpenses() {
      //const searchQueryLower = this.searchQuery.toLowerCase();
      //return this.expenses.filter(expense =>
      //  expense.Name.toLowerCase().includes(searchQueryLower)
      //);
      return this.expenses;
    },
    // Function to calculate the total of all expenses
    totalExpenses() {
      return this.expenses.reduce((total, expense) => total + expense.Betrag, 0);
    },
// Function to fetch expenses data
    async fetchData() {
      try {
        const auth = fbauth;

        if (auth.currentUser) {
          const authToken = await auth.currentUser.getIdToken();
          let url = `${config.apiUrl}/haushaltsbuch/`;

          if (this.searchQuery) {
            url += `search?query=${encodeURIComponent(this.searchQuery)}`;
            console.log(url);
          } else {
            url += `monat?Monat=${this.selectedMonth}&Jahr=${this.selectedYear}`;
          }

          const response = await axios.get(url, {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${authToken}`
            }
          });

          this.expenses = response.data.sort((a, b) => new Date(b.Datum) - new Date(a.Datum));
        } else {
          console.warn("User is not authenticated");
        }
      } catch (error) {
        console.error("Error fetching expenses:", error);
      }
    }
  },
  created() {
    // Fetch expenses data when component is created
    this.fetchData();

    // Observer for authentication state changes
    const unsubscribe = fbauth.onAuthStateChanged((user) => {
      console.log("Auth state changed:", user);
      // Fetch data when the authentication state changes
      this.fetchData();
    });

    // Lifecycle hook to unsubscribe from the observer when the component is unmounted
    onUnmounted(() => {
      console.log("Component is unmounted. Unsubscribing from auth observer.");
      unsubscribe();
    });

    // Watch for changes in expenses to trigger the filteredExpenses computation
    watch(() => this.expenses, () => {
      console.log("Expenses changed. Recomputing filteredExpenses.");
      // Assuming you want to recompute filteredExpenses whenever expenses change
      // You can call the filteredExpenses function here if needed
    });

    // Watch for changes in searchQuery to trigger the fetchData method
    watch(() => this.searchQuery, () => {
      console.log("Search query changed. Fetching data.");
      this.fetchData();
    });
  }
};
</script>



<style scoped>
.yellowish-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 0, 0.5); /* Yellowish with 50% opacity */
  pointer-events: none;
  z-index: 1; /* Ensure the yellowish layer is above the card */
}

.redish-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.5); /* Yellowish with 50% opacity */
  pointer-events: none;
  z-index: 1; /* Ensure the yellowish layer is above the card */
}

.prio-1{
  background-color: darkgreen;
  color: white;
}

.prio-2{
  background-color: limegreen;
}

.prio-3{
  background-color: yellow;
}

.prio-4{
  background-color: orange;
}

.prio-5{
  background-color: orangered;
  color: white;
}

</style>
