<script>
import { computed } from "vue";
import { useRouter } from "vue-router";

export default {
  data() {
    return {};
  },

  computed: {
    isLoggedin() {
      return this.$store.getters.isLoggedIn;

    }
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/login");
    },
    closeNavbar() {
      const navbarCollapse = this.$refs.navbarSupportedContent;
      if (navbarCollapse && navbarCollapse.classList.contains('show')) {
        const collapseInstance = bootstrap.Collapse.getInstance(navbarCollapse);
        collapseInstance.hide();
      }
    }
  }
};
</script>

<template>


  <div v-if="isLoggedin">
    <nav class="navbar navbar-expand-lg bg-body-tertiary fixed-top">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">Dashboard</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent" ref="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link class="nav-link" to="/umzug" @click.native="closeNavbar">Umzug</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/comics" @click.native="closeNavbar">Comics</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/comics/wunschliste" @click.native="closeNavbar">Comics Wunschliste</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/haushaltsbuch" @click.native="closeNavbar">Haushaltsbuch</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/releases" @click.native="closeNavbar">Releases</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/releases/add" @click.native="closeNavbar">Release hinzufügen</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/rw21" @click.native="closeNavbar">RW21</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/movies" @click.native="closeNavbar">Movies</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/log" @click.native="closeNavbar">Log</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/status" @click.native="closeNavbar">Status</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/funktionen" @click.native="closeNavbar">Funktionen</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/scanner" @click.native="closeNavbar">Scanner</router-link>
            </li>
          </ul>
          <ul class="navbar-nav">
            <li class="nav-item">
              <button class="nav-link" @click="logout" @click.native="closeNavbar">Logout</button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>

  <div v-else>
    <nav class="navbar navbar-expand-lg bg-body-tertiary fixed-top">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">Navbar</a>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">

          </ul>
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link" to="/login">Login</router-link>
            </li>
          </ul>
        </div>
      </div>

    </nav>
  </div>

</template>

<style scoped lang="scss">

</style>