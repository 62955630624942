<template>
  <header>
    <Navbar/>
  </header>
  <main>
    <div class="container ">
      <router-view></router-view>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "App",
  components: {
    Navbar
  },
  watch:{
    didAutoLogout(curValue, oldValue){
      if(curValue && curValue !== oldValue){
        this.$router.replace("/login");
      }
    }

  },
  data() {
    return {

    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
