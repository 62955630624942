<template>
  <div class="container mt-5">
    <form @submit.prevent="login">
      <div class="mb-3">
        <label for="email" class="form-label">E-Mail</label>
        <input type="text" v-model.trim="email" class="form-control" id="username" required>
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Password</label>
        <input type="password" v-model.trim="password" class="form-control" id="password" required>
      </div>
      <button type="submit" class="btn btn-primary">Login</button>
    </form>

    <div :hidden="!isLoading" class="spinner-border" role="status">
      <span class="visually-hidden">Einloggen...</span>
    </div>

    <div :hidden="!error" class="alert alert-danger" role="alert">
      {{ error }}
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  data() {
    return {
      email: "",
      password: "",
      isLoading: false,
      error: null
    };
  },
  methods: {
    async login() {
      try {
        if (!this.isValidEmail(this.email)) {
          console.error("Invalid email format. Please enter a valid email address.");
          return;
        }

        this.isLoading = true;
        await this.$store.dispatch("login", { email: this.email, password: this.password });

        const redirectPath = localStorage.getItem("redirectPath") || "/";

        // Clear the saved path
        localStorage.removeItem("redirectPath");

        // Redirect the user to the originally requested page
        this.$router.replace(redirectPath);
      } catch (error) {
        this.error = error.message || "Unknown error during login.";
      } finally {
        this.isLoading = false;
      }
    },


    isValidEmail(email) {
      // Basic email format validation using a regular expression
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
  }

};
</script>

<style scoped>
/* Add your custom styles or override Bootstrap styles here */
</style>
