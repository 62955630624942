<script setup>
import { ref, onMounted, onUnmounted, watch, computed } from "vue";
import axios from "axios";
import { fbauth } from "@/firebase";
import { config } from "@/config";

const rw21Items = ref([]);
const ausgeliehen = ref([]);
const vorbestellung = ref([]);
let authToken = null;

const formatDateTime = (dateTimeString, isWithTime) => {
  let options = {};
  if (isWithTime){
    options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    };
  }
  else{
    options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    };
  }

  const date = new Date(dateTimeString);
  return date.toLocaleDateString("de-DE", options);
};

const pythonScriptMessage = ref("");
const isLoading = ref(false);
let eventSource = null;

const runPythonScript = async () => {
  try {
    isLoading.value = true;

    if (eventSource) {
      eventSource.close();
    }

    eventSource = new EventSource(`${config.apiUrl}/rw21/run`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      },
      withCredentials: true
    });

    eventSource.onmessage = (event) => {
      try {
        const eventData = JSON.parse(event.data);
        const outputMessage = eventData && eventData.output ? eventData.output : "No output available";
        console.log("Python script output:", outputMessage);
        pythonScriptMessage.value = `Python script finished: ${outputMessage}`;
      } catch (error) {
        console.error("Error parsing SSE message:", error);
      } finally {
        isLoading.value = false;
        eventSource.close();
      }
    };
  } catch (error) {
    console.error("Error running Python script:", error);
    pythonScriptMessage.value = "Error running Python script";
    isLoading.value = false;
  }
};

const fetchData = async () => {
  const auth = fbauth;

  if (auth.currentUser) {
    try {
      authToken = await auth.currentUser.getIdToken();
      const response = await axios.get(`${config.apiUrl}/rw21`, {
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        withCredentials: true
      });

      rw21Items.value = response.data.map((rw21) => ({
        ...rw21,
        ImageURL: rw21.ImageURL || null
      })).sort((a, b) => a.Mediengruppe.localeCompare(b.Mediengruppe));


      const response2 = await axios.get(`${config.apiUrl}/rw21/ausgeliehen`, {
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        withCredentials: true
      });

      ausgeliehen.value = response2.data.map((ausgeliehen) => ({
        ...ausgeliehen,
        ImageURL: ausgeliehen.ImageURL || null
      })).sort((a, b) => a.Frist.localeCompare(b.Frist));

      const response3 = await axios.get(`${config.apiUrl}/rw21/vorbestellung`, {
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        withCredentials: true
      });

      vorbestellung.value = response3.data.map((vorbestellung) => ({
        ...vorbestellung,
        ImageURL: vorbestellung.ImageURL || null
      }))


    } catch (error) {
      console.error("Error fetching data:", error);
    }
  } else {
    console.warn("User is not authenticated");
  }
};

const unsubscribe = fbauth.onAuthStateChanged(() => {
  fetchData();
});

onUnmounted(() => {
  unsubscribe();
  if (eventSource) {
    eventSource.close();
  }
});

watch(rw21Items, () => {
  console.log("rw21Items changed. Recomputing maxTimestamp.");
});
watch(ausgeliehen, () => {

});

const maxTimestamp = computed(() => {
  const timestamps = rw21Items.value
    .map((rw21) => new Date(rw21.Timestamp).toISOString())
    .filter((timestamp) => /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(timestamp));

  if (timestamps.length > 0) {
    const maxTimestamp = timestamps.reduce((a, b) => (a > b ? a : b));
    return maxTimestamp;
  } else {
    return "No entries available";
  }
});

const handleImageError = (event) => {
  console.error("Image loading error:", event);
};

// Methode zur Rückgabe der Hintergrundfarbe basierend auf der Mediengruppe
const getBackgroundColor = (mediengruppe) => {
  if (mediengruppe.startsWith("Buch")) {
    return "#FFEBCD"; // Beispielfarbe für Bücher
  } else if (mediengruppe.startsWith("DVD")) {
    return "#555555";
  } else if (mediengruppe.startsWith("BluRay")) {
    return "#0095d5"; // Beispielfarbe für DVDs und BluRays
  } else if (mediengruppe === "Switch Spiel") {
    return "#ff0000"; // Rot für Switch Spiele
  } else if (mediengruppe === "Playstation Spiel") {
    return "#003087"; // Blau für Playstation Spiele
  } else if (mediengruppe.startsWith("Spiel")) {
    return "#FF5500"; // Beispielfarbe für Spiele
  } else {
    return "transparent"; // Standard-Hintergrundfarbe
  }
};

const getBackgroundColorVorbestellung = (bereitgestellt) => {
  if (bereitgestellt === 1) {
    return "#00FF00"; // Grün für bereitgestellte Vorbestellungen
  } else {
    return "#FF0000"; // Rot für nicht bereitgestellte Vorbestellungen
  }
};

const getRowClass = (mediengruppe) => {
  if (mediengruppe.endsWith("Comic")) {
    return "table-secondary";
  } else if (mediengruppe.startsWith("Buch")) {
    return "table-light"; // Beispielfarbe für Bücher
  } else if (mediengruppe.startsWith("DVD")) {
    return "table-dark";
  } else if (mediengruppe.startsWith("BluRay")) {
    return "table-info"; // Beispielfarbe für DVDs und BluRays
  } else if (mediengruppe === "Switch Spiel") {
    return "table-danger"; // Rot für Switch Spiele
  } else if (mediengruppe === "PlayStation Spiel") {
    return "table-primary"; // Blau für Playstation Spiele
  } else if (mediengruppe.startsWith("Spiel")) {
    return "table-warning"; // Beispielfarbe für Spiele
  } else {
    return "transparent"; // Standard-Hintergrundfarbe
  }
};
</script>

<template>
  <div class="text-center">
    <h1 class="display-4">RW21</h1>

    <button type="button" class="btn btn-primary" @click="runPythonScript" :disabled="isLoading">Python Script
      ausführen
    </button>

    <div v-if="isLoading" class="spinner-border" role="status">
      <span class="visually-hidden">Läuft...</span>
    </div>

    <div class="console-box" v-if="pythonScriptMessage">
      <pre>{{ pythonScriptMessage }}</pre>
    </div>

    <p>Stand: {{ formatDateTime(maxTimestamp, true) }}</p>

    <a href="https://wwwopac.stadt.bayreuth.de/de-de/Meine-Bibliothek" target="_blank">zur Kontoübersicht</a>

    <div class="container">
      <h2>Ausgeliehen</h2>
      <div class="table-responsive">
        <table class="table table-bordered table-responsive table-hover table-sm">
          <thead>
          <tr>
            <th>Mediengruppe</th>
            <th>Titel</th>
            <th>Bild</th>
            <th>Frist</th>
            <th>Verbleibend</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="ausgeliehen in ausgeliehen" :key="ausgeliehen.Title" :class="getRowClass(ausgeliehen.Mediengruppe)"
              :style="{ backgroundColor: getBackgroundColor(ausgeliehen.Mediengruppe) + ' !important' }">
            <td>{{ ausgeliehen.Mediengruppe }}</td>
            <td>
              {{ ausgeliehen.Title }}
            </td>
            <td>
              <img v-if="ausgeliehen.ImageURL" :src="`${ausgeliehen.ImageURL}?token=${authToken}`" alt="Bild"
                   @error="handleImageError" />
              <span v-else>No Image</span>
            </td>
            <td>{{ formatDateTime(ausgeliehen.Frist, false) }}</td>
            <td>{{ Math.floor((new Date(ausgeliehen.Frist) - new Date()) / (1000 * 60 * 60 * 24))+1}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="container">
      <h2>Vorbestellungen</h2>
      <div class="table-responsive">
        <table class="table table-bordered table-responsive table-hover table-sm">
          <thead>
          <tr>
            <th>Mediengruppe</th>
            <th>Titel</th>
            <th>Bild</th>
            <th>Bereitgestellt</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="vorbestellung in vorbestellung" :key="vorbestellung.Title" :class="getRowClass(vorbestellung.Mediengruppe)"
              :style="{ backgroundColor: getBackgroundColorVorbestellung(vorbestellung.Bereitgestellt) + ' !important' }">
            <td>{{ vorbestellung.Mediengruppe }}</td>
            <td>
              {{ vorbestellung.Title }}
            </td>
            <td>
              <img v-if="vorbestellung.ImageURL" :src="`${vorbestellung.ImageURL}?token=${authToken}`" alt="Bild"
                   @error="handleImageError" />
              <span v-else>No Image</span>
            </td>
            <td>{{vorbestellung.Bereitgestellt===1 ? "Ja" : "Nein"}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="container">
      <h2>Merkliste</h2>
      <div class="table-responsive">
        <table class="table table-bordered table-responsive table-hover table-sm">
          <thead>
          <tr>
            <th>Mediengruppe</th>
            <th>Titel</th>
            <th>Bild</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="rw21 in rw21Items" :key="rw21.Title" :class="getRowClass(rw21.Mediengruppe)"
              :style="{ backgroundColor: getBackgroundColor(rw21.Mediengruppe) + ' !important' }">
            <td>{{ rw21.Mediengruppe }}</td>
            <td>
              <a :href="rw21.URL" target="_blank">{{ rw21.Title }}</a>
            </td>
            <td>
              <img v-if="rw21.ImageURL" :src="`${rw21.ImageURL}?token=${authToken}`" alt="Bild"
                   @error="handleImageError" />
              <span v-else>No Image</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.console-box {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  font-family: monospace;
}

.custom-table-row {
  background-color: transparent !important; /* Set background color for the entire row */
}
</style>
