import { createRouter, createWebHistory } from "vue-router";
import Comics from "@/components/Comics.vue";
import Releases from "@/components/Releases.vue";
import Login from "@/components/Login.vue";
import store from "@/store";
import RW21 from "@/components/RW21.vue";
import { fbauth } from "@/firebase";
import { ref } from "vue";
import AddMovie from "@/components/AddMovie.vue";
import Log from "@/components/Log.vue";
import Movies from "@/components/Movies.vue";
import ReleaseHinzufuegen from "@/components/ReleaseHinzufuegen.vue";
import ComicsWunschliste from "@/components/ComicsWunschliste.vue";
import Funktionen from "@/components/Funktionen.vue";
import Haushaltsbuch from "@/components/Haushaltsbuch.vue";
import Startseite from "@/components/Startseite.vue";
import Status from "@/components/Status.vue";
import Scanner from "@/components/Scanner.vue";
import Umzug from "@/components/Umzug.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/startseite" },
    { path: "/startseite", component: Startseite },
    { path: "/comics", component: Comics },
    { path: "/comics/wunschliste", component: ComicsWunschliste },
    { path: "/releases", component: Releases },
    { path: "/releases/add", component: ReleaseHinzufuegen },
    { path: "/rw21", component: RW21 },
    { path: "/log", component: Log },
    { path: "/status", component: Status },
    { path: "/login", component: Login },
    { path: "/movies", component: Movies },
    { path: "/funktionen", component: Funktionen },
    { path: "/haushaltsbuch", component: Haushaltsbuch },
    { path: "/scanner", component: Scanner },
    { path: "/umzug", component: Umzug },
  ],
  linkActiveClass: "active"
});

const user = ref(null);

// Listen for changes in the authentication state
fbauth.onAuthStateChanged((authUser) => {
  user.value = authUser;

  // Use authUser directly in the condition to check if the user is logged in
  if (authUser) {
    store.dispatch("updateLoginStatus", {
      token: authUser.getIdToken(),
      userId: authUser.uid,
      user: authUser,
      isLoggedIn: true
    });

    localStorage.setItem("isLoggedIn", "1");
    localStorage.setItem("token", authUser.getIdToken());
  } else {
    localStorage.setItem("isLoggedIn", "0");
    localStorage.setItem("token", null);
    store.dispatch("updateLoginStatus", {
      token: null,
      userId: null,
      user: null,
      isLoggedIn: false
    });

    // Redirect to the login page if the user is not authenticated
    router.replace("/login");
  }
});

function handleLoginStatus() {
  console.log("handleLoginStatus");
  const isLoggedIn = fbauth.currentUser !== null;
  const user = fbauth.currentUser;

  if (isLoggedIn) {
    store.dispatch("updateLoginStatus", {
      token: user.getIdToken(),
      userId: user.uid,
      user: user,
      isLoggedIn: true
    });

    localStorage.setItem("isLoggedIn", "1");
  } else {
    localStorage.setItem("isLoggedIn", "0");
    store.dispatch("updateLoginStatus", {
      token: null,
      userId: null,
      user: null,
      isLoggedIn: false
    });

    // Redirect to the login page if the user is not authenticated
    router.replace("/login");
  }
}

router.beforeEach((to, from, next) => {
  // Check if the user is logged in based on localStorage
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "1";

  // Set a flag indicating whether login is required
  const loginRequired = !isLoggedIn && to.path !== "/login";

  if (loginRequired) {
    // Save the original path so you can redirect after login
    localStorage.setItem("redirectPath", to.path);

    // Redirect them to the '/login' route
    next({ path: "/login", replace: true });
  } else {
    // Continue with the navigation
    next();
  }
});

export default router;
