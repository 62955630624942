export default {
  setUser(state, payload) {
    state.token = payload.token;
    state.userId = payload.userId;
    state.user = payload.user;
  },

  setIsLoggedIn(state, payload) {
    state.isLoggedIn = payload.isLoggedIn;
  }

};