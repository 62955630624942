<script setup>
import { onUnmounted, ref } from "vue";
import { fbauth } from "@/firebase";
import axios from "axios";
import { config } from "@/config";

const status = ref([]);

const formatDate = (dateString) => {
  const options = { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit" };
  const date = new Date(dateString);
  return date.toLocaleDateString("de-DE", options);
};

const fetchData = async () => {
  const auth = fbauth;

  if (auth.currentUser) {
    try {
      const authToken = await auth.currentUser.getIdToken();

      const response = await axios.get(`${config.apiUrl}/log/status?pass=pass`);

      status.value = response.data.map((statusEntry) => ({
        ...statusEntry,
        Timestamp: formatDate(statusEntry.Timestamp)
      }));

      console.log(status)

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  } else {
    console.warn("User is not authenticated");
  }
};

// Observer for authentication state changes
const unsubscribe = fbauth.onAuthStateChanged((user) => {
  console.log("Auth state changed:", user);
  // Fetch data when the authentication state changes
  fetchData();
});

// Lifecycle hook to unsubscribe from the observer when the component is unmounted
onUnmounted(() => {
  console.log("Component is unmounted. Unsubscribing from auth observer.");
  unsubscribe();
});

</script>

<template>
  <div>
    <h2>Status</h2>

    <table class="table">
      <thead>
      <tr>
        <th scope="col">Timestamp</th>
        <th scope="col">Program Name</th>
        <th scope="col">Running</th>
        <th scope="col">Letzer Status</th>
        <th scope="col">Letzter Log Eintrag</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(statusEntry, index) in status" :key="index" :class="{'table-success': statusEntry.Running, 'table-danger': statusEntry.LetzterStatus === 'ERROR'}">
        <td>{{ statusEntry.Timestamp }}</td>
        <td>{{ statusEntry.Name }}</td>
        <td>{{ statusEntry.Running }}</td>
        <td>{{ statusEntry.LetzterStatus }}</td>
        <td>{{ statusEntry.LetzterLogEintrag }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="scss">
/* Add any additional styles here if needed */
</style>
