import { fbauth } from "@/firebase";
import { signInWithEmailAndPassword } from "@firebase/auth";
import { browserLocalPersistence, setPersistence } from "firebase/auth";

//let timer;

export default {
  async login(context, payload) {

    /*
    const apiKey = "AIzaSyADjiVNaCrt4Wf8CskMrqFMEe86_hdNiNc"; // Replace with your actual API key

    const response = await fetch(`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${apiKey}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: payload.email,
        password: payload.password,
        returnSecureToken: true
      })
    });

        const responseData = await response.json();
    if (!response.ok) {
      throw new Error(responseData.message || "Failed to authenticate. Check your login data.");
    }

    const expiresIn = +responseData.expiresIn * 1000;
    const expirationDate = new Date().getTime() + expiresIn;

    localStorage.setItem("token", responseData.idToken);
    localStorage.setItem("userId", responseData.localId);
    localStorage.setItem("tokenExpiration", expirationDate);

    timer = setTimeout(function() {
      context.dispatch("autoLogout");
    }, expiresIn);
    */


    const userCredential = await signInWithEmailAndPassword(fbauth, payload.email, payload.password);
    const user = userCredential.user;




    const idToken = userCredential.user.getIdToken();
    const expirationTimeInSeconds = idToken.exp; // Expiration time in seconds

    const expiresIn = +expirationTimeInSeconds * 1000;

    context.commit("setUser", {
      token: idToken,
      userId: user.uid
    });


  },

  updateLoginStatus(context, payload) {
    context.commit("setUser", {
      token: payload.token,
      userId: payload.userId,
      user: payload.user
    });
    context.commit("setIsLoggedIn", {
      isLoggedIn: payload.isLoggedIn
    })

    localStorage.setItem("isLoggedIn", payload.isLoggedIn ? "1" : "0");
    localStorage.setItem("token", payload.token);
  },

/*
  autoLogin(context, payload) {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const tokenExpiration = localStorage.getItem("tokenExpiration");

    const expiresIn = +tokenExpiration - new Date().getTime();
    const expirationDate = new Date().getTime() + expiresIn;

    if (expiresIn < 0) {
      return;
    }

    timer = setTimeout(function() {
      context.dispatch("autoLogout");
    }, expiresIn);

    if (token && userId) {

      context.commit("setUser", {
        token: token,
        userId: ""
      });

    }

  },

 */

  async logout(context) {
    /*
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("tokenExpiration");

    clearTimeout(timer);

    context.commit("setUser", {
      token: null,
      userId: null
    });
    */
    try {
      // Sign out the user
      await fbauth.signOut();

      // Optionally, you can redirect to another page or perform additional actions after logout
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  },

  /*
  autoLogout(context) {
    context.dispatch("logout");
    context.commit("setAutoLogout");
  }

   */

};