<script setup>
import { ref } from "vue";
import axios from "axios";
import { fbauth } from "@/firebase";
import { config } from "@/config";

const releases = ref([]);
const confirmationModal = ref(false);
const editModal = ref(false);
const confirmationMessage = ref("");
let selectedRelease = null;

const weekday = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];

const formatDate = (dateString) => {
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("de-DE", options);
};

const formatToInputDate = (dateString) => {
  const [day, month, year] = dateString.split('.');
  return `${year}-${month}-${day}`;
};

const fetchData = async () => {
  const auth = fbauth;

  if (auth.currentUser) {
    try {
      const authToken = await auth.currentUser.getIdToken();

      const response = await axios.get(`${config.apiUrl}/releases`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      releases.value = response.data
        .map((release) => ({
          ...release,
          Wochentag: weekday[new Date(release.ReleaseDate).getDay()],
          ReleaseDate: formatDate(release.ReleaseDate)

        }))
        .filter((release) => release.Gesehen !== true)
        .sort((a, b) => new Date(a.ReleaseDate) - new Date(b.ReleaseDate));
      console.log(releases.value);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  } else {
    console.warn("User is not authenticated");
  }
};

const openConfirmationModal = (release) => {
  selectedRelease = { ...release };
  confirmationMessage.value = `${release.Titel} als gesehen markieren?`;
  confirmationModal.value = true;
};

const closeConfirmationModal = () => {
  confirmationModal.value = false;
};

const markAsSeen = async () => {
  try {
    const response = await axios.post(`${config.apiUrl}/releases/read`, {
      id: selectedRelease.ID,
    });
    await fetchData();
    closeConfirmationModal();
  } catch (error) {
    console.error("Error marking as seen:", error);
  }
};

const openEditModal = (release) => {
  selectedRelease = {...release, ReleaseDate: formatToInputDate(release.ReleaseDate) };
  editModal.value = true;
};

const closeEditModal = () => {
  editModal.value = false;
};

const updateRelease = async () => {
  try {
    const authToken = await fbauth.currentUser.getIdToken();
    const response = await axios.post(`${config.apiUrl}/releases/update`, selectedRelease, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    await fetchData();
    closeEditModal();
  } catch (error) {
    console.error("Error updating release:", error);
  }
};

fetchData();

</script>



<template>
  <div class="text-center">
    <h1 class="display-4">Releases</h1>

    <div class="container">
      <div class="table-responsive">
        <table class="table table-bordered table-responsive table-hover table-sm">
          <thead>
          <tr>
            <th>Cover</th>
            <th>ReleaseDate</th>
            <th>Titel</th>
            <th>Kategorie</th>
            <th>Platform</th>
            <th>TageVerbleibend</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="release in releases" :class="{ 'table-danger': release.TageVerbleibend > 0 }" :key="release.id">
            <td>
              <img :src="release.ImageURL" alt="Cover" class="img-fluid" style="height: 100%; width:auto; max-height:25vh;" />
            </td>
            <td>{{ release.Wochentag + ", " + release.ReleaseDate }}</td>
            <td><a :href="release.URL" target="_blank">{{ release.Titel }}</a></td>
            <td>{{ release.Kategorie }}</td>
            <td>{{ release.Platform }}</td>
            <td>{{ release.TageVerbleibend }}</td>
            <td>
              <button class="btn btn-success" @click="openConfirmationModal(release)">Gesehen</button>
              <button class="btn btn-primary" @click="openEditModal(release)">Bearbeiten</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Confirmation Modal -->
    <div class="modal" tabindex="-1" role="dialog" style="display: block;" v-if="confirmationModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Als gesehen markieren</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeConfirmationModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ confirmationMessage }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" @click="closeConfirmationModal">Nein</button>
            <button type="button" class="btn btn-success" @click="markAsSeen">Ja</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Modal -->
    <div class="modal" tabindex="-1" role="dialog" style="display: block;" v-if="editModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Release bearbeiten</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeEditModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="editTitle">Titel</label>
              <input v-model="selectedRelease.Titel" type="text" class="form-control" id="editTitle" />
            </div>
            <div class="form-group">
              <label for="editDate">Release Datum</label>
              <input v-model="selectedRelease.ReleaseDate" type="date" class="form-control" id="editDate" />
            </div>
            <div class="form-group">
              <label for="editCategory">Kategorie</label>
              <input v-model="selectedRelease.Kategorie" type="text" class="form-control" id="editCategory" />
            </div>
            <div class="form-group">
              <label for="editPlatform">Plattform</label>
              <input v-model="selectedRelease.Platform" type="text" class="form-control" id="editPlatform" />
            </div>
            <div class="form-group">
              <label for="editUrl">URL</label>
              <input v-model="selectedRelease.URL" type="text" class="form-control" id="editURL" />
            </div>
            <div class="form-group">
              <label for="editImageURL">ImageURL</label>
              <input v-model="selectedRelease.ImageURL" type="text" class="form-control" id="editImageURL" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" @click="closeEditModal">Abbrechen</button>
            <button type="button" class="btn btn-success" @click="updateRelease">Speichern</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped lang="scss">

</style>