<script setup>
import { ref, onMounted, watch, onUnmounted, computed } from "vue";
import axios from "axios";
import { fbauth } from "@/firebase";
import { config } from "@/config";

const comics = ref([]);
const filteredComics = ref([]);
const markedReadFilter = ref(["false"]); // Default: False
const languageFilter = ref(["Deutsch"]); // Default: Deutsch
const formatFilter = ref(["Paperback"]); // Default: Paperback
const startDateFilter = ref("2022-01-01"); // Default: 01.01.2022
const endDateFilter = ref(new Date().toISOString().slice(0, 10)); // Default: Today
const publisherNameFilter = ref(["Marvel", "Panini", "Marvel (Panini Comics)", "Panini Comics", "Marvel Comics"]); // Default: Marvel, Panini

// Function to fetch data
const fetchData = async () => {
  try {
    const auth = fbauth;

    if (auth.currentUser) {
      const authToken = await auth.currentUser.getIdToken();

      const response = await axios.get(`${config.apiUrl}/comics-collection`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });

      comics.value = response.data.sort((a, b) => new Date(a.ReleaseDate) - new Date(b.ReleaseDate));
      applyFilters();
    } else {
      console.warn("User is not authenticated");
      // Handle the case where the user is not authenticated
    }

  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Observer for authentication state changes
const unsubscribe = fbauth.onAuthStateChanged((user) => {
  console.log("Auth state changed:", user);
  // Fetch data when the authentication state changes
  fetchData();
});

// Lifecycle hook to unsubscribe from the observer when the component is unmounted
onUnmounted(() => {
  console.log("Component is unmounted. Unsubscribing from auth observer.");
  unsubscribe();
});

// Watch for changes in comics to trigger the filteredComics computation
watch([comics, markedReadFilter, languageFilter, formatFilter, startDateFilter, endDateFilter, publisherNameFilter], () => {
  console.log("Filters changed. Recomputing filteredComics.");
  applyFilters();
});

// Function to apply filters on comics
const applyFilters = () => {
  filteredComics.value = comics.value
    .filter(comic => (markedReadFilter.value.length === 0 || markedReadFilter.value.includes(comic.MarkedRead.toString())))
    .filter(comic => (languageFilter.value.length === 0 || languageFilter.value.includes(comic.Language)))
    .filter(comic => (formatFilter.value.length === 0 || formatFilter.value.includes(comic.Format)))
    .filter(comic => new Date(comic.ReleaseDate) >= new Date(startDateFilter.value) && new Date(comic.ReleaseDate) <= new Date(endDateFilter.value))
    .filter(comic => (publisherNameFilter.value.length === 0 || publisherNameFilter.value.includes(comic.PublisherName)));
};

// Get all unique formats and publishers for dynamic filter options
const allFormats = computed(() => {
  const formatsSet = new Set();
  comics.value.forEach(comic => formatsSet.add(comic.Format));
  return ["all", ...formatsSet].sort();

});

const allPublishers = computed(() => {
  const publishersSet = new Set();
  comics.value.forEach(comic => publishersSet.add(comic.PublisherName));
  return ["all", ...publishersSet].sort();
});

// Computed property to calculate the total cover price for unpurchased comics
const getTotalCoverPrice = computed(() => {
  return filteredComics.value.reduce((total, comic) => {
    if (comic.DatePurchased === null) {
      return total + (comic.CoverPrice || 0); // Adding cover price, handling possible null values
    }
    return total;
  }, 0);
});



</script>


<template>
  <!--  <div class="table-responsive">
      <table class="table table-hover table-sm">
        <thead>
        <tr>
          <th scope="col">Title</th>
          <th scope="col">Release Date</th>
          <th scope="col">Marked Read</th>
          <th scope="col">Image</th>
        </tr>
        </thead>
        <tbody class="table-group-divider">
        <tr v-for="comic in filteredComics" :key="comic.id">
          <td><a :href="comic.URL_LoCBG" target="_blank">{{comic.FullTitle }}</a></td>
          <td>{{ comic.ReleaseDate }}</td>
          <td>{{ comic.MarkedRead }}</td>
          <td>
            &lt;!&ndash; Assuming ImageUrl is a path to the image &ndash;&gt;
            <img :src="comic.ImageUrl" alt="Comic Image" style="max-width: 100px; max-height: 100px;" />
          </td>
        </tr>
        </tbody>
      </table>
    </div>-->


  <div>
    <div class="container">
      <h4>Total Items: {{ filteredComics.length }}</h4>
      <h4 v-if="filteredComics.length > 0">Total Cover Price for Unpurchased Comics: {{ getTotalCoverPrice }}€</h4>
    </div>

    <div class="container">
      <!-- Filter collapse button -->
      <button class="btn btn-primary mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#filterCollapse"
              aria-expanded="false" aria-controls="filterCollapse">
        Filter
      </button>
    </div>

    <div class="collapse container" id="filterCollapse">
      <div class="mb-3">
        <!-- Filter for comic.MarkedRead -->
        <label for="markedReadFilter">Marked Read:</label>
        <select v-model="markedReadFilter" id="markedReadFilter" class="form-select" multiple>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>

        <!-- Filter for comic.Language -->
        <label for="languageFilter">Language:</label>
        <select v-model="languageFilter" id="languageFilter" class="form-select" multiple>
          <option value="Deutsch">Deutsch</option>
          <option value="Englisch">Englisch</option>
        </select>

        <!-- Filter for comic.Format -->
        <label for="formatFilter">Format:</label>
        <select v-model="formatFilter" id="formatFilter" class="form-select" multiple>
          <option v-for="format in allFormats" :key="format">{{ format }}</option>
        </select>

        <!-- Filter for comic.ReleaseDate -->
        <label for="startDateFilter">Release Date Start:</label>
        <input type="date" v-model="startDateFilter" id="startDateFilter" class="form-control">
        <label for="endDateFilter">Release Date End:</label>
        <input type="date" v-model="endDateFilter" id="endDateFilter" class="form-control">

        <!-- Filter for comic.PublisherName -->
        <label for="publisherNameFilter">Publisher Name:</label>
        <select v-model="publisherNameFilter" id="publisherNameFilter" class="form-select" multiple>
          <option v-for="publisher in allPublishers" :key="publisher">{{ publisher }}</option>
        </select>
      </div>
    </div>


    <div class="row row-cols-2 row-cols-md-3 g-4">
      <div v-for="comic in filteredComics" :key="comic.id" class="col">
        <div :class="'card h-100 ' + (comic.MarkedRead ? 'bg-success' : '')">
          <!-- Yellowish transparent layer when DatePurchased is null -->
          <div v-if="comic.DatePurchased === null" class="yellowish-layer"></div>

          <div class="row g-0 m-auto">
            <div class="col-md-4">
              <img class="card-img-top" :src="comic.ImageUrl" :alt="'Cover ' + comic.FullTitle">
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <h5 class="card-title">{{ comic.FullTitle }}</h5>
                <p class="card-text">{{ comic.ReleaseDate ? new Date(comic.ReleaseDate).toLocaleDateString() : "" }}</p>
                <p class="card-text">Preis: {{ comic.CoverPrice }}€</p>
                <p><a :href="comic.URL_Paninishop">Panini</a></p>
                <p><a :href="comic.URL_LoCBG">LOCG</a></p>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <span :class="'me-1 badge text-' + (comic.MarkedRead ? 'bg-success' : 'bg-danger')">
              {{ comic.MarkedRead ? "Gelesen" : "Ungelesen" }}
            </span>
            <span v-if="comic.DatePurchased === null" class="badge text-bg-warning">Nicht gekauft</span>
            <span class="badge text-bg-info ">{{ comic.MonthsTillToday }} M</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>
.yellowish-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 0, 0.5); /* Yellowish with 50% opacity */
  pointer-events: none;
  z-index: 1; /* Ensure the yellowish layer is above the card */
}
</style>