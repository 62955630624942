<script setup>
import { onMounted } from "vue";

// Load the WerStreamt.es widget script asynchronously
onMounted(() => {
  const script = document.createElement("script");
  script.src = "https://www.werstreamt.es/_resources/themes/wse/js/widget.v4.min.js";
  script.async = true;
  script.charset = "utf-8";
  document.head.appendChild(script);
});


const movies = [
  {
    id: 1,
    title: "Die Reifeprüfung",
    wseLink: "https://www.werstreamt.es/film/details/5762/die-reifepruefung/"
  },
  {
    id: 2,
    title: "The Graduate",
    wseLink: "https://www.werstreamt.es/film/details/5762/die-reifepruefung/"
  }
];
</script>

<template>
  <div v-for="movie in movies" :key="movie.id">
    <div class="wse-widget"><a rel="noopener" href="https://www.werstreamt.es/film/details/5762/die-reifepruefung/"
                               target="_blank">Verfügbarkeit von Die Reifeprüfung auf WerStreamt.es prüfen</a>
    </div>
  </div>



</template>

<style scoped lang="scss">

</style>


